<!--
 * @Descripttion:
 * @Author: gaorongrong
 * @Date: 2023-08-07 17:14:20
 * @LastEditors: gaorongrong
 * @LastEditTime: 2023-08-08 10:15:56
-->
<template>
  <div class="journal">
    <div class="topTitle">协会期刊</div>
    <div class="content">
      <div class="box">
        <div class="pic" @click="linkTo(data.thumbnail)">
          <img :src="data.annexToken" alt="" srcset="" />
        </div>
        <div class="text">
          <div class="number">
            <span class="number-total" v-if="total"><i>总第</i> <span style="position: relative;top: 1px;">{{ `${total<10?0:''}${total}`}}</span> <i>期</i> </span>
            <p v-else>暂无数据</p>
          </div>
          <div class="year">
           <span v-if="data.publishDate"> {{ data.publishDate}}年度</span>
           <span v-if="data.title"> {{ data.title}}期 </span>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="pic">
          <img :src="img2" alt="" srcset="" />
        </div>
        <div class="text">
          <div
            class="more"
            @click="$router.push({name:'AssociationWork',query:{index:'6'}})"
          >
            查看更多
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img2 from '../../assets/images/位图@2x.png'
import img1 from '../../assets/images/位图@2x (1).png'
import { getFileList, downloadFile, downloadImage } from '../../api/getFile.js'
export default {
  props: ['bgColor'],
  components: {},
  name: 'associationJournal',
  data () {
    return {
      img1,
      img2,
      list: [
        // {
        //   url: img1,
        //   number: '02',
        //   year: 2023,
        //   linkUrl: 'https://books-1.fanyeshu.cn/oss/UXYUea/'
        // },
        // {
        //   url: img2
        // }
      ],
      data: {},
      params: {},
      dataList: [],
      total: 0,
    }
  },
  mounted () {
    this.getJournal()
  },
  watch: {},
  computed: {},
  methods: {
    getJournal () {
      const data = Object.assign({}, this.param)
      data.logotype = '协会期刊'
      data.pageNo = 1
      data.pageSize = 5
      getFileList(data).then(res => {
        if (res.status && res.data.data.list.length > 0) {
          this.data = res.data.data.list[0]
          const count =res.data.data.total||0
          this.total = count
          // console.log(this.data, 'jou')
          if (this.data.publishDate) {
            this.data.publishDate = this.data.publishDate.split('-')[0]
          }
          downloadFile({ g9s: [this.data.annexToken] }).then(res => {
            // console.log(res.data.data, '55')
            // downloadImage(res.data.data[0].fileToken).then(res => {
            //   console.log(res, '66')
            //   const blob = new Blob([res.data], {
            //     type: `image/${res.data.extName}`
            //   })
            //   this.data.annexToken = URL.createObjectURL(blob)
            // })
            const blob = `/api/sys-storage/download_image?f8s=${res.data.data[0].fileToken}`
            this.data.annexToken = blob
          })
          // console.log(this.data, 'data')
        }
      })
    },
    linkTo (url) {
      if (url) {
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.journal {
  width: 100%;
  height: 690px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  box-sizing: border-box;
  .topTitle {
    font-size: 36px;
    font-family: YouSheBiaoTiHei;
    color: #2c3e5c;
    text-align: center;
    margin-bottom: 180px;
  }
  .content {
    display: flex;
    .box {
      width: 550px;
      height: 265px;
      margin-right: 20px;
      background: #f9f9f9;
      padding: 0 0 24px 32px;
      box-sizing: border-box;
      display: flex;
      &:last-child {
        margin-right: 0;
      }
      .pic {
        width: 265px;
        height: 363px;
        // background-color: aqua;
        transform: translateY(-122px);
        img {
          width: 100%;
          height: 100%;
          background-size: 265px 363px;
        }
      }
      .text {
        margin-left: 48px;
        margin-top: 101px;
        .number {
          margin-bottom: 17px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #555555;
          span {
            // display: inline-block;
            width: 56px;
            height: 57px;
            font-size: 48px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #191919;
            line-height: 57px;
            margin-right: 4px;
            i{
              font-size: 20px;
              font-weight: 400;
              font-family: MicrosoftYaHei;
              font-style:normal;
              color: #555555;
            }
          }
          p{
            margin-top: 78px;
          }
        }
        .year {
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #555555;
        }
        .more{
          margin-top: 78px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #555555;
        }
      }
    }
  }
}
</style>
